const teacher = [
    { icon: "flaticon-video-camera", label: "Now Playing", path: 'teacher.liveClass.nowPlaying' },
    { icon: "flaticon-check-list", label: "Next Live", path: 'teacher.liveClass.nextLive' },
    { icon: "flaticon-event", label: "Schedule", path: 'teacher.liveClass.schedule' },
];

const student = [
    { icon: "flaticon-video-camera", label: "Now Playing", path: 'student.liveClass.nowPlaying' },
    { icon: "flaticon-check-list", label: "Next Live", path: 'student.liveClass.nextLive' },
    { icon: "flaticon-event", label: "Schedule", path: 'student.liveClass.schedule' },
];

const nextLives = [];
for (let i = 0; i < 15; i++) {
    nextLives.push({
       id: i,
       name: 'Importance of speaking',
       description: 'lorem ipsum dolor site ame',
       startedAt: '24 Jan, 2020',
       time: '5:45pm',
       medium: 'Facebook',
       section: 'Speaking',
       status: '45 Min'
   });
}

const nowPlayings = [];
for (let i = 0; i < 15; i++) {
    nowPlayings.push({
       id: i,
       name: 'Importance of speaking',
       relatedMaterials: 'https://ajsvd1cacset.com/ases',
       date: '24 Jan, 2020',
       section: 'Listening',
       time: '5:45pm',
       duration: '2:15:00',
       managedBy: 'Kazi Shahin'
   });
}



export default {
    topNavigation: {
        teacher,
        student
    },
    nextLives,
    nowPlayings
};