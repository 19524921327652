<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>play_arrow</md-icon>
            <h3 class="pl-2 text-uppercase">Next Live</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-4 flex justify-between justify-xs-center">
          <SelectComponent class="w-64 m-1" />
        </div>
        <md-divider></md-divider>
        <Table :items="nextLives" @onSelect="onSelect">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="NAME" class="text-victoria">
              <img class="mr-2" width="35" src="/images/thumbnail.png" alt="">
              {{ item.name }}
            </md-table-cell>
            <md-table-cell md-label="DESCRIPTION">{{ item.description }}</md-table-cell>
            <md-table-cell md-label="STARTED AT">{{ item.startedAt }}</md-table-cell>
            <md-table-cell md-label="TIME">{{ item.time }}</md-table-cell>
            <md-table-cell md-label="MEDIUM">{{ item.medium }}</md-table-cell>
            <md-table-cell md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-label="STATUS" class="text-victoria">{{ item.status }}</md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, Table } from "@/components";
import data from "@/data/live-class/data"
import { mapMutations } from "vuex";

export default {
  name: "Blog-List-View",
  components: {
    Card,
    Table,
    CardBody,
    SelectComponent,
  },
  data() {
    return {
      nextLives: data.nextLives
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect() {

    }
  }
};
</script>
